import { tokensConfig } from './tokens';

export const PEARL_PACK_DATA = [
  {
    price: 10,
    tokenReward: 1000,
    pearlBlackReward: 0,
    name: 'Mini handful of Pearls',
    imagePath: 'pearl-1000',
    token: tokensConfig.pearl,
    key: 'packMini'
  },
  {
    price: 50,
    tokenReward: 5000,
    pearlBlackReward: 0,
    name: 'Standard handful of Pearls',
    imagePath: 'pearl-5000',
    token: tokensConfig.pearl,
    key: 'packStandard'
  },
  {
    price: 100,
    tokenReward: 10000,
    pearlBlackReward: 0,
    name: 'Handful of Pearls',
    imagePath: 'pearl-10000',
    token: tokensConfig.pearl,
    key: 'packHandful'
  },
  {
    price: 250,
    tokenReward: 25000,
    pearlBlackReward: 0,
    name: 'Extra handful of Pearls',
    imagePath: 'pearl-25000',
    token: tokensConfig.pearl,
    key: 'packExtraHandful'
  },
  {
    price: 500,
    tokenReward: 50000,
    pearlBlackReward: 0,
    name: 'Shell Full of Pearls',
    imagePath: 'pearl-50000',
    token: tokensConfig.pearl,
    key: 'packShell'
  },
  {
    price: 1000,
    tokenReward: 100000,
    pearlBlackReward: 500,
    name: 'Pouch Full of Pearls',
    imagePath: 'pearl-100000',
    token: tokensConfig.pearl,
    key: 'packPouch'
  },
  {
    price: 2500,
    tokenReward: 250000,
    pearlBlackReward: 1625,
    name: 'Bag Full of Pearls',
    imagePath: 'pearl-250000',
    token: tokensConfig.pearl,
    key: 'packBag'
  },
  {
    price: 5000,
    tokenReward: 500000,
    pearlBlackReward: 3500,
    name: 'Extra bag Full of Pearls',
    imagePath: 'pearl-500000',
    token: tokensConfig.pearl,
    key: 'packExtraBag'
  },
  {
    price: 10000,
    tokenReward: 1000000,
    pearlBlackReward: 8500,
    name: 'Small Plate of Pearls',
    imagePath: 'pearl-1000000',
    token: tokensConfig.pearl,
    key: 'packSmallPlate'
  },
  {
    price: 15000,
    tokenReward: 1500000,
    pearlBlackReward: 14000,
    name: 'Plate Full of Pearls',
    imagePath: 'pearl-1500000',
    token: tokensConfig.pearl,
    key: 'packPlateFull'
  },
  {
    price: 20000,
    tokenReward: 2000000,
    pearlBlackReward: 19500,
    name: 'Big Plate Full of Pearls',
    imagePath: 'pearl-2000000',
    token: tokensConfig.pearl,
    key: 'packBigPlateFull'
  },
  {
    price: 25000,
    tokenReward: 2500000,
    pearlBlackReward: 25000,
    name: 'Tank Full of Pearls',
    imagePath: 'pearl-2500000',
    token: tokensConfig.pearl,
    key: 'packFullOfPearls'
  }
];

export const COAL_PACK_DATA = [
  {
    price: 10,
    tokenReward: 10000,
    pearlBlackReward: 0,
    name: 'Mini handful of Coal',
    imagePath: 'coal-10000',
    token: tokensConfig.coal,
    key: 'miniHandfulOfCoal'
  },
  {
    price: 100,
    tokenReward: 102000,
    pearlBlackReward: 0,
    name: 'Standard handful of Coal',
    imagePath: 'coal-102000',
    token: tokensConfig.coal,
    key: 'standardHandfulOfCoal'
  },
  {
    price: 500,
    tokenReward: 515000,
    pearlBlackReward: 0,
    name: 'Handful of Coal',
    imagePath: 'coal-515000',
    token: tokensConfig.coal,
    key: 'handfulOfCoal'
  },
  {
    price: 1000,
    tokenReward: 1045000,
    pearlBlackReward: 0,
    name: 'Small pouch of Coal',
    imagePath: 'coal-1045000',
    token: tokensConfig.coal,
    key: 'smallPouchOfCoal'
  },
  {
    price: 2500,
    tokenReward: 2662500,
    pearlBlackReward: 0,
    name: 'Pouch full of Coal',
    imagePath: 'coal-2662500',
    token: tokensConfig.coal,
    key: 'pouchFullOfCoal'
  },
  {
    price: 10000,
    tokenReward: 10900000,
    pearlBlackReward: 0,
    name: 'Crate of Coal',
    imagePath: 'coal-10900000',
    token: tokensConfig.coal,
    key: 'crateOfCoal'
  }
];

export const GOLD_ORE_PACK_DATA = [
  {
    price: 10,
    tokenReward: 20,
    pearlBlackReward: 0,
    name: 'Mini handful of Gold Ore',
    imagePath: 'gold-ore-20',
    token: tokensConfig.goldOre,
    key: 'miniHandfulOfGoldOre'
  },
  {
    price: 100,
    tokenReward: 210,
    pearlBlackReward: 0,
    name: 'Standard handful of Gold Ore',
    imagePath: 'gold-ore-210',
    token: tokensConfig.goldOre,
    key: 'standardHandfulOfGoldOre'
  },
  {
    price: 500,
    tokenReward: 1050,
    pearlBlackReward: 0,
    name: 'Handful of Gold Ore',
    imagePath: 'gold-ore-1050',
    token: tokensConfig.goldOre,
    key: 'HandfulOfGoldOre'
  },
  {
    price: 1000,
    tokenReward: 2200,
    pearlBlackReward: 0,
    name: 'Small pouch of Gold Ore',
    imagePath: 'gold-ore-2200',
    token: tokensConfig.goldOre,
    key: 'smallPouchOfGoldOre'
  },
  {
    price: 2500,
    tokenReward: 5500,
    pearlBlackReward: 0,
    name: 'Pouch of Gold Ore',
    imagePath: 'gold-ore-5500',
    token: tokensConfig.goldOre,
    key: 'pouchOfGoldOre'
  },
  {
    price: 10000,
    tokenReward: 23000,
    pearlBlackReward: 0,
    name: 'Crate of Gold Ore',
    imagePath: 'gold-ore-23000',
    token: tokensConfig.goldOre,
    key: 'crateOfGoldOre'
  }
];
