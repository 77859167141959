import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as marketplaceOHXB2te2S9Meta } from "/workspace/pages/marketplace.vue?macro=true";
import { default as referrallfm4JwuN6hMeta } from "/workspace/pages/referral.vue?macro=true";
import { default as storyhm6x9597GQMeta } from "/workspace/pages/story.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace",
    path: "/marketplace",
    component: () => import("/workspace/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    component: () => import("/workspace/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "story",
    path: "/story",
    component: () => import("/workspace/pages/story.vue").then(m => m.default || m)
  }
]