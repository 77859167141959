import { defaultConfig, createWeb3Modal } from '@web3modal/ethers/vue';
import { defineNuxtPlugin, useRequestURL } from 'nuxt/app';
import useEnvs from '~/composables/useEnvs';

const chainIdToName = (chainId: number) => {
  switch (chainId) {
    case 56:
      return 'BNB Smart Chain Mainnet';
    case 97:
      return 'BNB Smart Chain Testnet';
    default:
      return 'unknown chain';
  }
};

const chainIdToSymbol = (chainId: number) => {
  switch (chainId) {
    case 56:
      return 'BNB';
    case 97:
      return 'tBNB';
    default:
      return 'unknown symbol';
  }
};

export default defineNuxtPlugin({
  name: 'web3-modal',
  hooks: {
    'vue:setup'() {
      const { blockchain } = useEnvs();
      const projectId = blockchain.walletConnectProjectId;
      const { protocol, host } = useRequestURL();
      const currentBaseUrl = `${protocol}//${host}`;

      const chain = {
        chainId: blockchain.networkId,
        name: chainIdToName(blockchain.networkId),
        currency: chainIdToSymbol(blockchain.networkId),
        explorerUrl: blockchain.blockScanUrl,
        rpcUrl: blockchain.nodeUrl
      };

      const metadata = {
        name: 'Pearldiver',
        description: 'Pearldiver game',
        url: currentBaseUrl,
        icons: ['']
      };
      const ethersConfig = defaultConfig({
        metadata,

        enableEIP6963: true,
        enableInjected: true,
        enableCoinbase: true,
        rpcUrl: blockchain.nodeUrl, // used for the Coinbase SDK
        defaultChainId: blockchain.networkId // used for the Coinbase SDK
      });

      // 5. Create a Web3Modal instance
      createWeb3Modal({
        ethersConfig,
        //@ts-ignore
        chains: [chain],
        projectId,
        enableAnalytics: true,
        enableOnramp: true,
        enableWalletFeatures: true,
        termsConditionsUrl: 'https://walletconnect.com/terms',
        privacyPolicyUrl: 'https://walletconnect.com/privacy',
        includeWalletIds: [
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
          '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
          '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
          '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
          'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
        ]
      });
    }
  }
});
