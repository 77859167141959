import type { ContractsAbi } from '~/types/contractAddresses';

export enum Tokens {
  pearl = 'pearl',
  gold = 'gold',
  goldOre = 'goldOre',
  coal = 'coal',
  iron = 'iron',
  ironOre = 'ironOre',
  palladium = 'palladium',
  platina = 'platina',
  platinaOre = 'platinaOre',
  rhodium = 'rhodium',
  silver = 'silver',
  silverOre = 'silverOre',
  wood = 'wood',
  blackPearl = 'blackPearl',
  testNft = 'testNft'
}

export type TTokensConfigItem = {
  name: Record<string, keyof ContractsAbi>;
  media: string;
  label: string;
  methodName: string;
};
export type TTokensConfig = Record<Tokens, TTokensConfigItem>;

export const tokensConfig: TTokensConfig = {
  [Tokens.pearl]: {
    name: {
      contract: 'pearl',
      store: 'pearlStore',
      yield: 'pearlYield'
    },
    methodName: 'Pearl',
    label: 'corePearl',
    media: '/img/tokens/pearl.png'
  },
  [Tokens.gold]: {
    name: {
      contract: 'gold',
      store: 'goldStore',
      yield: 'goldYield'
    },
    label: 'coreGold',
    methodName: 'Gold',
    media: '/img/tokens/gold.png'
  },
  [Tokens.goldOre]: {
    name: {
      contract: 'goldOre',
      store: 'goldOreStore',
      yield: 'goldOreYield'
    },
    label: 'coreGoldOre',
    methodName: 'GoldOre',
    media: '/img/tokens/goldOre.png'
  },
  [Tokens.coal]: {
    name: {
      contract: 'coal',
      store: 'coalStore',
      yield: 'coalYield'
    },
    label: 'coreCoal',
    methodName: 'Coal',
    media: '/img/tokens/coal.png'
  },
  [Tokens.iron]: {
    name: {
      contract: 'iron',
      store: 'ironStore',
      yield: 'ironYield'
    },
    label: 'coreIron',
    methodName: 'Iron',
    media: '/img/tokens/iron.png'
  },
  [Tokens.ironOre]: {
    name: {
      contract: 'ironOre',
      store: 'ironOreStore',
      yield: 'ironOreYield'
    },
    label: 'coreIronOre',
    methodName: 'Iron Ore',
    media: '/img/tokens/ironOre.png'
  },
  [Tokens.palladium]: {
    name: {
      contract: 'palladium',
      store: 'palladiumStore',
      yield: 'palladiumYield'
    },
    label: 'corePalladium',
    methodName: 'Palladium',
    media: '/img/tokens/palladium.png'
  },
  [Tokens.platina]: {
    name: {
      contract: 'platina',
      store: 'platinaStore',
      yield: 'platinaYield'
    },
    label: 'corePlatina',
    methodName: 'Platina',
    media: '/img/tokens/platina.png'
  },
  [Tokens.platinaOre]: {
    name: {
      contract: 'platinaOre',
      store: 'platinaOreStore',
      yield: 'platinaOreYield'
    },
    label: 'corePlatinaOre',
    methodName: 'Platina Ore',
    media: '/img/tokens/platinaOre.png'
  },
  [Tokens.rhodium]: {
    name: {
      contract: 'rhodium',
      store: 'rhodiumStore',
      yield: 'rhodiumYield'
    },
    label: 'coreRhodium',
    methodName: 'Rhodium',
    media: '/img/tokens/rhodium.png'
  },
  [Tokens.silver]: {
    name: {
      contract: 'silver',
      store: 'silverStore',
      yield: 'silverYield'
    },
    label: 'coreSilver',
    methodName: 'Silver',
    media: '/img/tokens/silver.png'
  },
  [Tokens.silverOre]: {
    name: {
      contract: 'silverOre',
      store: 'silverOreStore',
      yield: 'silverOreYield'
    },
    label: 'coreSilverOre',
    methodName: 'Silver Ore',
    media: '/img/tokens/silverOre.png'
  },
  [Tokens.wood]: {
    name: {
      contract: 'wood',
      store: 'woodStore',
      yield: 'woodYield'
    },
    label: 'coreWood',
    methodName: 'Wood',
    media: '/img/tokens/wood.png'
  },
  [Tokens.blackPearl]: {
    name: {
      contract: 'blackPearl'
    },
    label: 'coreBlackPearl',
    methodName: 'Black Pearl',
    media: '/img/icons/blackPearl.png'
  },
  [Tokens.testNft]: {
    name: {
      contract: 'testNft'
    },
    label: 'Test Nft',
    methodName: 'Test Nft',
    media: '/img/tokens/test-nft.png'
  }
};
