import { defineNuxtPlugin } from 'nuxt/app';
import { useMainStore } from '~/stores/mainStore';

export default defineNuxtPlugin(() => {
  const store = useMainStore();

  return {
    provide: {
      errorHandler: (error: string) => {
        if (process.client) {
          store.setError(error);
          console.error(error);
        }
      }
    }
  };
});
