import { defineNuxtPlugin } from 'nuxt/app';
import useEnvs from '~/composables/useEnvs';
import { formatNumber } from '~/utils';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatDate: (value: string) => {
        if (!value) {
          return '';
        }
        return new Date(value).toLocaleString();
      },
      formatValue: (value: string) => {
        return formatNumber(Number(value).toFixed(2));
      },
      txLink: (value: string) => {
        return useEnvs().blockchain.blockScanUrl + '/tx/' + value;
      }
    }
  };
});
