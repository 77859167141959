import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      firstLetterToCapCase: (target: string) => {
        const lettersArray = target.split('');
        lettersArray[0] = lettersArray[0].toUpperCase();
        return lettersArray.join('');
      }
    }
  };
});
