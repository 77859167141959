export const PEARL_EXCHANGE_RATE = 0.01;

export const SUPPORT_EMAIL = 'support@pearldivergame.com';

export const PROVIDER_LOW_GAS_ERROR_CODE = -32603;
export const USER_REJECT_ACTION_CODE = 4001;
export const PROVIDER_GAS_ERROR = `
Couldn't proceed the transaction 
likely because of the low gas value. Please try initiate the transaction again and manually increase the gas value by editing the "estimated fee" 
field (click the edit icon and choose the advanced option)`;

export const ACTION_REJECTED = 'ACTION_REJECTED';
export const ACTION_REJECTED_SHORT_ERROR = 'Transaction cancelled.';
export const ACTION_REJECTED_ERROR = `Transaction cancelled.
After an unsuccessful attempt to claim your rewards,
your funds are reserved for 30 minutes for security purposes.
After 30 minutes, your funds will be returned to the general “Available for Claim” account.
Keep an eye on the timer.`;

export const SUCCEEDED_TRANSACTION_LIFETIME = 900000;

export const INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const NOT_ENOUGH_BNB_TO_PAY_FOR_GAS = 'Not enough BNB to pay for gas transactions';
